.dropdown-toggle-select {
    display: flex;
    justify-content: center;
    align-items: center;

    span {
        padding: 10px;
    }
}

.profile-line {
    padding: 14px 20px 20px;

    &__title {
        font-weight: 600;
        margin-bottom: 5px;
    }

    &__elem {
        overflow: hidden;
        height: 15px;
        width: 100%;
        border-radius: 15px;
        background-color: #f5f5f5;
        box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    }

    &__value {
        height: 100%;
        border-radius: 15px;
        background-color: #5d78ff;
    }
}

.profile-line.mobile-none {
    display: none !important;

    @media (max-width: 1024px) {
        display: block !important;
    }
}
