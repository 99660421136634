// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";

// Default Layout Skins
@import "./_metronic/_assets/sass/global/layout/header/skins/base/light.scss";
@import "./_metronic/_assets/sass/global/layout/header/skins/menu/light.scss";
@import "./_metronic/_assets/sass/global/layout/brand/skins/dark.scss";
@import "./_metronic/_assets/sass/global/layout/aside/skins/dark.scss";

// Header Skins
.kt-header-base-light {
  @import "./_metronic/_assets/sass/global/layout/header/skins/base/light.scss";
}

.kt-header-base-dark {
  @import "./_metronic/_assets/sass/global/layout/header/skins/base/dark.scss";
}

// Header Menu Skins
.kt-header-menu-light {
  @import "./_metronic/_assets/sass/global/layout/header/skins/menu/light.scss";
}
.kt-header-menu-dark {
  @import "./_metronic/_assets/sass/global/layout/header/skins/menu/dark.scss";
}

// Brand Skins
.kt-brand-dark {
  @import "./_metronic/_assets/sass/global/layout/brand/skins/dark.scss";
}

.kt-brand-light {
  @import "./_metronic/_assets/sass/global/layout/brand/skins/light.scss";
}

// Aside skins
.kt-aside-dark {
  @import "./_metronic/_assets/sass/global/layout/aside/skins/dark.scss";
}

.kt-aside-light {
  @import "./_metronic/_assets/sass/global/layout/aside/skins/light.scss";
}

// Web Icon fonts
// @import "~socicon/css/socicon.css";
// @import "~@fortawesome/fontawesome-free/css/all.min.css";
// @import "./_metronic/_assets/plugins/line-awesome/css/line-awesome.css";
// @import "./_metronic/_assets/plugins/flaticon/flaticon.css";
// @import "./_metronic/_assets/plugins/flaticon2/flaticon.css";

@-webkit-keyframes fadeInUpContent {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -5%, 0);
    transform: translate3d(0, -5%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUpContent {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -5%, 0);
    transform: translate3d(0, -5%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUpContent {
  -webkit-animation-name: fadeInUpContent;
  animation-name: fadeInUpContent;
}

@import url("https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap&subset=latin-ext");
@import url("https://fonts.googleapis.com/css?family=Audiowide&display=swap&subset=latin-ext");
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Hind:wght@300;400;500;600;700&display=swap");

* {
  font-family: "Poppins";
}

.kt-portlet__head-title {
  text-transform: uppercase;
  font-weight: 600;
}

#root iframe {
  height: 100%;
}

#HW_badge_cont {
  height: 100%;
  width: 100%;
  position: absolute;

  #HW_badge {
    top: 8px;
    right: 8px;
    left: auto;
  }
}

@media (min-width: 600px) {
  .MuiTab-root {
    min-width: 100px !important;
  }
}

button.btn.btn-primary,
button.btn.btn-info,
button.btn.btn-secondary {
  background-color: #e5e9f0 !important;
  border: none !important;
  color: black !important;
  opacity: 1;

  &:hover,
  &:active {
    opacity: 0.8;
  }

  &:disabled {
    opacity: 0.6;
    cursor: auto;
  }
}

button.btn.btn-primary.google-sync__button {
  background-color: #fff !important;
  border: thin solid #cecece !important;
  color: #777 !important;
  box-shadow: 1px 1px 1px #888;
}

button.btn.btn-blue {
  // background-color: #5D78FF!important;
  background-color: #3669ff !important;
  border: none !important;
  color: white !important;
  opacity: 1;

  &:hover,
  &:active {
    // background-color: #5d78ff !important;
    background-color: #3669ff !important;
    color: #f5f9ff !important;
    opacity: 0.8;
  }
}

button.btn.btn-danger {
  background-color: #ff722c !important;
  border: none !important;
  color: #3b3c3f !important;

  &:hover,
  &:active {
    background-color: #f44719 !important;
    color: #f5f9ff !important;
  }
}

.show > .btn-primary.dropdown-toggle {
  background-color: #3b3c3f !important;
  color: #f5f9ff !important;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #f5f9ff;
  color: #3b3c3f;
}

@media (max-width: 599.95px) {
  .MuiTabs-scrollButtonsDesktop {
    display: inline-flex !important;
  }
}

@media (max-width: 768px) {
  .kt-hidden-mobile {
    display: flex !important;
  }
}

.wrap-setting {
  overflow: auto;

  & > table {
    min-width: 600px;
    width: 100%;
  }
}

.kt-aside-dark
  .kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item.kt-menu__item--active
  > .kt-menu__link
  .kt-menu__link-icon {
  color: white;
}

.kt-aside-dark
  .kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item.kt-menu__item--active
  > .kt-menu__link
  .kt-menu__link-text {
  font-weight: 600;
}

.MuiOutlinedInput-multiline {
  padding: 6px 0 7px !important;
}

.MuiInputLabel-outlined {
  transform: translate(14px, 14px) scale(1) !important;
  /*
  position: absolute;
  top: 50% !important;
  transform: translate(14px, -50%) scale(1) !important;
  */
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
  /* top: 0 !important; */
  transform: translate(14px, -6px) scale(0.75) !important;
}

.MuiOutlinedInput-input {
  padding: 12px 14px !important;
}

.alert {
  font-weight: 600;
}

.alert.alert-success {
  background-color: #d9f5f4; //#dff0d8;
  border-color: transparent; // #d6e9c6;
  color: #15bcbc; // #468847;

  .alert-text {
    color: #b94a48;
  }
}

.alert.alert-danger {
  background-color: #f2dede;
  border-color: transparent; //#eed3d7;
  color: #b94a48;

  .alert-text {
    color: #b94a48;
  }
}

.kt-content
  .kt-portlet
  > .kt-portlet__head
  .kt-portlet__head-label
  .kt-portlet__head-title {
  color: #646c9a;
}

.MuiSelect-select {
  padding-right: 30px !important;
}

.kt-header__topbar-user {
  min-width: 120px;
}

.MuiSelect-icon {
  right: 6px !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
}

.info-block {
  &__photo {
    margin-top: 30px;

    &-preview {
      border: 1px dashed grey;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 300px;
      width: 100%;
      padding: 30px;
      position: relative;

      .delete {
        position: absolute;
        top: 5px;
        right: 5px;
        cursor: pointer;
      }

      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }
  }
}

@media screen and (max-height: 600px) {
  .footer-auth-hidden {
    display: none !important;
  }
}

.CbHosted {
  iframe {
    height: 2.2em !important;
  }
}

.modal-open {
  overflow: hidden !important;
}

.acord {
  @media screen and (max-width: 767px) {
  }
}

.acord-title {
  display: none;
  width: 100%;
  border: 1px solid #646c9a;
  border-radius: 5px;
  padding: 5px 10px;
  margin: 5px 0;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;

  @media screen and (max-width: 767px) {
    display: flex;
  }
}

.acord-none-mob {
  display: flex !important;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 767px) {
    display: none !important;
  }
}
.acord-none-dt {
  display: none !important;

  @media screen and (max-width: 767px) {
    display: flex !important;
  }
}

.menu-none-dt {
  display: none !important;

  @media screen and (max-width: 1024px) {
    display: flex !important;
  }
}

iframe[title="intercom"] {
  bottom: 80px;
  right: -100px;
}

.metric-radio .MuiTypography-body1 {
  font-size: 11px !important;
  line-height: 11px !important;
}

.MuiFormGroup-root label {
  margin-bottom: 5px !important;
}

.grecaptcha-badge {
  visibility: hidden;
}

.pagination-show {
  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    background-color: #fff;
  }
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
  background-color: #fff;
  padding: 0 5px;
}

.scrollable-container::-webkit-scrollbar {
  display: auto !important;
  display: auto !important;
}

.force-scroll {
  -webkit-overflow-scrolling: touch !important;
}

.tasks-counter p {
  margin: 0;
  font-size: 15px;
  position: relative;
  left: -0.5px;
  top: 1px;
}

.tasks-counter {
  min-width: 20px;
  height: 20px;
  background-color: #f44719;
  color: white;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateY(-50%);
  padding: 4px;
}

.w-full {
  width: 100%;
}

.wrap-setting,
.settingtable,
.table-container,
.new-calendar__table-container,
.create-workout-exercise-table,
.MuiPopover-paper,
.calendar-component {
  table {
    width: 100%;
    font-size: 14px;
    th {
      white-space: nowrap;
    }
    th,
    td {
      padding: 8px 10px;
    }
  }

  @media screen and (max-width: 767px) {
    & {
      &::-webkit-scrollbar {
        -webkit-appearance: none;
      }
      &::-webkit-scrollbar:vertical {
        width: 4px;
      }
      &::-webkit-scrollbar:horizontal {
        height: 4px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        border: 2px solid #c1c1c1; /* should match background, can't be transparent */
        background-color: #c1c1c1;
      }
    }
  }
}

.data {
  @media screen and (max-width: 767px) {
    .date-picker-wrap:first-child .modal-d {
      left: 0 !important;
    }
  }
}

@media screen and (max-width: 767px) {
  .pagination-wrap {
    margin-top: 20px;
    flex-direction: column;
  }

  .pagination {
    margin-bottom: 20px !important;
    margin: 0 auto 20px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }

  .pagination {
    li {
      font-size: 14px;
    }
  }
  .pagination-lg .page-link {
    padding: 1rem 2rem;
    font-size: 18px;
    display: flex;
    justify-content: center;
  }
  .pagination-show {
    margin-bottom: 10px;
    margin-right: auto;

    .formControl {
      width: 100px !important;
      margin-left: 0 !important;
    }
  }
}

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]
  .MuiAutocomplete-endAdornment {
  right: 5px !important;
}
.MuiAutocomplete-endAdornment {
  top: calc(50%) !important;
  transform: translateY(-50%) !important;
}
